import React from "react";
import { Utilities } from "../../utilities";
import { MakeAQueryBttn } from "../../components/MakeAQueryBttn";
import { CustomTable } from "../../components";
import { translate } from "../../translations/localization";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./styles.scss";
import { getSingleProduct } from "../../actions";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { history } from "../../config/stores";
import Lottie from "react-lottie";
import animation from "../../LottieAnimations/HydroPro-loading-inbutton.json";
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import Modals from "../../components/ModalComponent/Modals";

export default function ProductDesktopNew() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const productData = useSelector((state) => state.products.singleProduct);
  const [loaded, setLoaded] = useState(false);
  const [viewImageModal, setViewImageModal] = useState({ open: false, image: null });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    renderSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [data] = useState({
    nav1: "",
    nav2: "",
    categories: [],
    id: "",
    title: "",
    Products: [],
    description: "",
    quality: "",
    efficiency: "",
    application: "",
    techinfo: [],
    drawings: [],
    installation: "",
    images: [],
    product: "",
    table: null,
  });

  const techinfoList = Utilities.translateLanguage(productData.technicalInformation)?.map((techinfo, i) => (
    <div key={i} className="techinfo-list">
      <a href={techinfo} target="_blank" rel="noopener noreferrer" className="link" title="">
        {Utilities?.renderUrl(techinfo) || techinfo || ""}
      </a>
    </div>
  ));

  const drawingsList = Utilities.translateLanguage(productData.drawings)?.map((drawings, j) => (
    <div key={j} className="drawings-list" style={j % 2 === 1 ? { marginBottom: "15px" } : {}}>
      <a href={drawings} target="_blank" rel="noreferrer noopener" title="">
        {Utilities?.renderUrl(drawings) || drawings || ""}
      </a>
    </div>
  ));

  const handleImageLoad = (index, length) => {
    if (index === length - 1) setLoaded(true);
  };

  useEffect(() => {
    dispatch(getSingleProduct({ _id: id }));
  }, [dispatch, id]);

  return (
    <div className="Product page-bg">
      {/* <Helmet>
        <script className="structured-data-list" type="application/ld+json">
          {JSON.stringify(structuredJSON)}
        </script>
      </Helmet> */}
      <div className="container">
        <div className="top-content">
          <div className="breadcrumbs" onClick={() => history.goBack()}>
            <img src={require("../../assets/icons/arrow-back-white.svg")} alt="< go back" className="arrow-icon" />
            <span className="top-text uppercase">{Utilities.translateLanguage(productData?.name)}</span>
          </div>
        </div>
        <div className="content">
          <MakeAQueryBttn />
          <div className="row">
            <div className="col col-Product-text">
              <div className="tabs-list">
                <Tabs>
                  <TabList>
                    <Tab>
                      <span tabname="За продукта">{translate("tabOptionAboutProduct")}</span>
                    </Tab>
                    {productData?.installation?.bg?.length ||
                    productData?.installation?.en?.length ||
                    productData?.technicalInformation?.bg?.length ||
                    productData?.technicalInformation?.en?.length ||
                    productData?.bimInformation?.bg?.length ||
                    productData?.bimInformation?.en?.length ||
                    productData?.bimFiles?.bg?.length ||
                    productData?.bimFiles?.en?.length ? (
                      <Tab>
                        <span tabname="Техническа информация">{translate("products-KMU-HydrooilDuoTabTechnicalInformation")}</span>
                      </Tab>
                    ) : (
                      ""
                    )}
                    {productData?.solutions?.bg?.[0]?.length || productData?.solutions?.en?.[0]?.length ? (
                      <Tab>
                        <span tabname="Решения">{translate("headerSolutions")}</span>
                      </Tab>
                    ) : (
                      ""
                    )}
                    {productData?.mediaPhotos?.length || productData?.mediaVideos?.length || productData?.drawings?.bg?.length || productData?.drawings?.en?.length ? (
                      <Tab>
                        <span tabname="Медия и чертежи">{translate("tabOptionMediaAndDrawings")}</span>
                      </Tab>
                    ) : (
                      ""
                    )}
                  </TabList>

                  <div className="scroll">
                    <div style={{ height: "95%", overflowY: "auto" }}>
                      <TabPanel>
                        <div>
                          <div className="box-p">
                            <h1>{translate("products-KMU-HydrooilBypassDescription")}</h1>
                            <p>{Utilities.translateLanguage(productData?.description)}</p>
                            {/* <p>{data?.description}</p> */}
                          </div>

                          {/* specifications link version */}
                          {productData?.specifications?.bg?.length || productData?.specifications?.en?.length ? (
                            <div className="box-p">
                              <h1>{translate("tabOptionSpecificationProduct")}</h1>
                              <p className="link" onClick={() => window.open(Utilities.translateLanguage(productData?.specifications))}>
                                {Utilities.translateLanguage(productData?.specifications)}
                              </p>
                            </div>
                          ) : (
                            <div />
                          )}

                          {/* specifications table version */}
                          {productData?.table?.bg?.length || productData?.table?.en?.length ? (
                            <div className="box-p">
                              <h1>{translate("tabOptionSpecificationProduct")}</h1>

                              {productData?.specifications?.bg?.length || productData?.specifications?.en?.length ? (
                                <p className="link" onClick={() => window.open(Utilities.translateLanguage(productData?.specifications))}>
                                  {Utilities.translateLanguage(productData?.specifications)}
                                </p>
                              ) : (
                                <div />
                              )}

                              <CustomTable data={Utilities.translateLanguage(productData?.table)} />
                            </div>
                          ) : (
                            <div />
                          )}

                          {Utilities.translateLanguage(productData?.appendix)?.length ? (
                            <div className="box-p">
                              <h1>{translate("products-KMU-HydrooilBypassApplication")}</h1>
                              <p>{Utilities.translateLanguage(productData?.appendix)}</p>
                            </div>
                          ) : (
                            ""
                          )}

                          {Utilities.translateLanguage(productData?.quality)?.length ? (
                            <div className="box-p">
                              <h1>{translate("products-KMU-HydrooilBypassQuality")}</h1>
                              <p>{Utilities.translateLanguage(productData?.quality)}</p>
                            </div>
                          ) : (
                            ""
                          )}

                          {Utilities.translateLanguage(productData?.effectiveness)?.length ? (
                            <div className="box-p">
                              <h1>{translate("products-KMU-HydrooilBypassЕfficiency")}</h1>
                              <p>{<p>{Utilities.translateLanguage(productData?.effectiveness)}</p>}</p>
                            </div>
                          ) : (
                            ""
                          )}

                          {productData?.manufacturer === "hydropro" && (
                            <div className="box-p">
                              <h1>{translate("producer")}</h1>
                              {/* <img src={require("../../assets/icons/white-hydropro.svg")} className="logo-new" alt="logo" /> */}
                              <div className="new-logo"/>
                            </div>
                          )}
                        </div>
                      </TabPanel>

                      {productData?.installation?.bg?.length ||
                      productData?.installation?.en?.length ||
                      productData?.technicalInformation?.bg?.length ||
                      productData?.technicalInformation?.en?.length ||
                      productData?.bimInformation?.bg?.length ||
                      productData?.bimInformation?.en?.length ||
                      productData?.bimFiles?.bg?.length ||
                      productData?.bimFiles?.en?.length ? (
                        <TabPanel>
                          <div>
                            {Utilities?.translateLanguage(productData?.installation)?.length ? (
                              <div className="box-p">
                                <h1>{translate("products-KMU-HydrooilBypassTabInstallation")}</h1>
                                <p>{Utilities?.translateLanguage(productData?.installation)}</p>
                              </div>
                            ) : (
                              ""
                            )}

                            {Utilities.translateLanguage(productData?.technicalInformation)?.length ? (
                              <div className="box-p">
                                <h1>{translate("products-KMU-HydrooilDuoTabTechnicalInformation")}</h1>
                                <div>{techinfoList}</div>
                              </div>
                            ) : (
                              ""
                            )}

                            {productData?.bimInformation?.bg?.length || productData?.bimInformation?.en?.length ? (
                              <div className="box-p">
                                <h1>Building Information Modeling – BIM</h1>
                                <p>{Utilities.translateLanguage(productData?.bimInformation)}</p>
                              </div>
                            ) : (
                              ""
                            )}

                            {productData?.bimFiles?.bg?.length || productData?.bimFiles?.en?.length ? (
                              <div className="box-p">
                                <h1>REVIT BIM FILES</h1>
                                <p>
                                  {Utilities.translateLanguage(productData?.bimFiles)?.map((file, i) => {
                                    return (
                                      <div key={`bim-file-${file}`} className="techinfo-list">
                                        <a href={file} target="_blank" rel="noopener noreferrer" className="link" title="">
                                          {Utilities?.renderUrl(file) || file || ""}
                                          {/* {file?.name || file || ''} */}
                                        </a>
                                      </div>
                                    );
                                  })}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </TabPanel>
                      ) : (
                        ""
                      )}

                      {productData?.solutions?.bg?.[0]?.length || productData?.solutions?.en?.[0]?.length ? (
                        <TabPanel>
                          <div>
                            {Utilities.translateLanguage(productData?.solutions)?.map((solution) => {
                              return (
                                <div className="box-p flex">
                                  <h1 className="border">{solution?.split(":")?.[0] + ":"}&nbsp;</h1>
                                  <span className="regular">{solution?.split(":")?.[1]}</span>
                                </div>
                              );
                            })}
                          </div>
                        </TabPanel>
                      ) : (
                        ""
                      )}
                      {productData?.drawings?.bg?.length || productData?.drawings?.en?.length || productData?.mediaPhotos?.length || productData?.mediaVideos?.length ? (
                        <div>
                          <div className={`${productData?.mediaPhotos?.length || productData?.mediaVideos?.length ? "box-p larger-padding" : ""}`}>
                            {productData?.mediaPhotos?.length || productData?.mediaVideos?.length ? <h1>{translate("tabOptionMedia")}</h1> : ""}

                            {productData?.mediaPhotos?.length || productData?.mediaVideos?.length ? (
                              <div className={`img-wrapper ${!loaded ? "center" : ""}`}>
                                {productData?.mediaPhotos?.map((img, index) => {
                                  return (
                                    <LazyLoadComponent threshold={200}>
                                      <LazyLoadImage
                                        src={img} // actual image
                                        className={`lazy-image`}
                                        alt="description"
                                        style={{ filter: "blur(10px)" }} // Apply CSS blur filter
                                        onLoad={(e) => {
                                          e.currentTarget.style.filter = "none";
                                          handleImageLoad(index, productData?.mediaPhotos?.length);
                                        }} // Remove blur on load
                                        onClick={() => setViewImageModal({ open: true, image: img })}
                                      />
                                    </LazyLoadComponent>
                                  );
                                  // return <img src={img} onLoad={() => handleImageLoad(index, productData?.mediaPhotos?.length)} alt="drawing" />;
                                })}

                                {productData?.mediaVideos?.map((video) => {
                                  return (
                                    <div className="video-container" style={{ position: "relative", height: "100%", width: "100%", cursor: "pointer" }}>
                                      <video controls={true} playsInline={false} muted={true} preload="auto" loop={true} autoPlay={true} style={{ position: "absolute", top: "20%", bottom: "73%", maxWidth: "100%", minWidth: "100%", height: "auto", objectFit: "contain" }}>
                                        <source src={video} type="video/mp4" />
                                      </video>
                                    </div>
                                  );
                                })}

                                {!loaded && <Lottie options={defaultOptions} height={50} width={50} />}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          {productData?.drawings?.bg?.length || productData?.drawings?.en?.length ? (
                            <div className="box-p">
                              <h1>{translate("products-KMU-HydrooilBypassTabDrawings")}</h1>
                              <div>{drawingsList}</div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalComponent open={viewImageModal.open} className={"overflow-y-auto"} children={<Modals.ViewImageModal handleClose={() => setViewImageModal({ open: false, image: null })} data={viewImageModal} setData={setViewImageModal} gallery={productData?.mediaPhotos} />} />
    </div>
  );
}
