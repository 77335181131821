import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { NavSide } from "../../components";
import { connect } from "react-redux";
import { setLanguage } from "../../actions";
import { translate } from "../../translations/localization";
import "./styles.scss";

class NavMainMobileItems extends Component {
  render() {
    return (
      <div className="nav-main-wrapper mobile">
        <div className="nav-main-mobile-items">
          <div className="item">
            <NavLink to="/home">
            <span>{translate("headerHome")}</span>
            </NavLink>
          </div>
          <div className="item">
            <NavLink className="item" to="/Products/home">
              <span>{translate("headerProducts")}</span>
            </NavLink>
          </div>
          {/* <div className="item">
            <NavLink to="/BIM">BIM</NavLink>
          </div> */}
          {/* <div className="item">
            <NavLink to="/solutions/urban">
              {translate("headerSolutions")}
            </NavLink>
          </div> */}
          <div className="item">
            <NavLink to="/references">{translate("headerReferences")}</NavLink>
          </div>
          {/* <div className="item">
            <NavLink to="/specialized-materials-list">{translate("headerCalculators")}</NavLink>
          </div> */}
          <div
            className="item"
            onClick={() => {
              this.props.onToggle();
            }}
          >
            {translate("headerCalculators")}
          </div>
          <div className="item">
            <NavLink to="/about-us">{translate("headerAboutUs")}</NavLink>
          </div>
          {/* <div className="item">
            <NavLink to="/sensors" target="_blank" >{translate("headerSensors")}</NavLink>
          </div> */}
        </div>

        <div className="languages-section-wrapper">
          <div className="languages-container">
            <div
              className={`lang ${this.props.language === "en" ? "active" : ""}`}
              onClick={() => {
                this.props.setLanguage("en");
                window.location.reload();
              }}
            >
              EN
            </div>
            <div
              className={`lang ${this.props.language === "bg" ? "active" : ""}`}
              onClick={() => {
                this.props.setLanguage("bg");
                window.location.reload();
              }}
            >
              BG
            </div>
          </div>
        </div>
        <NavSide />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  contactDetails: state.user.contactDetails,
  language: state.language.selectedLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (payload) => dispatch(setLanguage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavMainMobileItems);
