import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { startLoading, stopLoading } from "../../../actions";
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";
import "../styles.scss";

const ViewImageModal = ({ data, handleClose, gallery, setData, dispatch, fetch, curPage, setCurPage, articleId, limit, totalDocs }) => {
  const slidesCount = window.innerWidth > 2000 ? 20 : 12;
  const sliderRef = useRef();
  const [activeSlides, setActiveSlides] = useState(0);
  const [_, setImageLoading] = useState("");

  const [containerHeight, setContainerHeight] = useState(null);

  useEffect(() => {
    if (data?.open) {
      const slideIndex = Math.floor(data?.index / slidesCount);
      setActiveSlides(slideIndex * slidesCount);
      // document.getElementById("header").style.position = "relative";
      document.getElementById("header").style.zIndex = "1";
      if (sliderRef.current && sliderRef.current.slickGoTo) sliderRef.current.slickGoTo(slideIndex * slidesCount);
    }
  }, [data]);

  const defaultSlickSettings = {
    infinite: false,
    speed: 500,
    slidesToShow: slidesCount,
    lazyLoad: true,
    slidesToScroll: slidesCount,
    arrows: false,
    beforeChange: (current, next) => {
      setActiveSlides(next);
    },
  };

  const resizeDiv = (imageUrl, divElement) => {
    let img = new Image();
    img.onload = function () {
      let maxWidth = 900; // Maximum width
      let maxHeight = 700; // Maximum height
      let width = this.width;
      let height = this.height;

      // Calculate the ratio
      let ratio = Math.min(maxWidth / width, maxHeight / height);

      // Set the dimensions
      divElement.style.width = `${width * ratio}px`;
      divElement.style.height = `${height * ratio}px`;
      setContainerHeight(`${height * ratio}px`);
    };
    img.src = imageUrl;
  };

  const goToNextSlide = () => {
    if (gallery?.length < totalDocs) {
      if (gallery?.length <= slidesCount * curPage) {
        setImageLoading(true);
        fetch({
          articleId,
          page: curPage,
          limit: limit,
          noLoading: true,
          onSuccess: () => {
            setImageLoading(false);
            if (sliderRef.current) sliderRef.current.slickNext();
            setCurPage(curPage + 1);
          },
        });
      } else if (sliderRef.current) sliderRef.current.slickNext();
    }
  };

  const goToPreviousSlide = () => {
    if (activeSlides === 0) if (sliderRef.current) sliderRef.current.slickPrev();
  };

  const sliderSelectAsset = (asset, index) => {
    setData({ ...data, image: asset, index: index });
    resizeDiv(asset, document.getElementById("image-holder"));
  };

  return (
    <div className="view-image-container">
      <div
        className="close-icon"
        onClick={() => {
          document.getElementById("html").style.overflow = "auto";
          document.getElementById("header").style.zIndex = "99";
          handleClose();
        }}
      />

      <div className="view-image-container">
        <LazyLoadComponent>
          <div className={`lazy-image-container`}>
            <LazyLoadImage
              src={data?.image}
              className="rounded-md lazy-image !max-h-[700px]"
              alt="description"
              itemID="image-holder"
              id="image-holder"
              style={{ filter: "blur(10px)" }}
              onLoad={(e) => {
                e.currentTarget.style.filter = "none";
                resizeDiv(data?.image, document.getElementById("image-holder"));
              }}
            />
          </div>
        </LazyLoadComponent>
      </div>
      <div style={{ width: "99%", display: "flex", justifyContent: "flex-end", marginBottom: "4px" }}>
        <img src={require("../../../assets/icons/arrow-back-white.svg")} alt="< go back" className="arrow-icon" onClick={() => goToPreviousSlide()} />
        <img src={require("../../../assets/icons/arrow-back-white.svg")} alt="< go back" className="arrow-icon" style={{ transform: "rotate(180deg)", marginLeft: "10px" }} onClick={() => goToNextSlide()} />
      </div>
      {gallery?.length && (
        <div style={{ height: "150px", width: "100%" }}>
          <Slider {...defaultSlickSettings} ref={sliderRef}>
            {gallery?.map((asset, index) => (
              <React.Fragment key={asset?._id}>
                <div className={`gallery-slick-image relative w-[98%] h-24 rounded-md flex justify-center cursor-pointer flex-wrap`}>
                  <img src={asset} alt="Thumbnail" className={`object-fill max-w-full max-h-full ${asset ? "border-2 border-red" : ""}`} onClick={() => sliderSelectAsset(asset, index)} />
                </div>
              </React.Fragment>
            ))}
          </Slider>
        </div>
      )}
    </div>
  );
};

export default ViewImageModal;
