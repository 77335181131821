import React from "react";
import { Utilities } from "../../utilities";
import PerfectScrollbar from "react-perfect-scrollbar";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { translate } from "../../translations/localization";
import { useSelector, useDispatch } from "react-redux";
import { getProducts, getCategories, startLoading } from "../../actions";
import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import "./styles.scss";
import _ from "lodash";
import Lottie from "lottie-react";
import * as animationData from "../../assets/animations/loading.json";

export default function ProductsDesktopNew() {
  const dispatch = useDispatch();
  const productsRef = useRef();
  const categoriesData = useSelector((state) => state.products.categories);
  const hasNextPage = useSelector((state) => state.products.hasNextPage);
  const nextPage = useSelector((state) => state.products.nextPageCategory);
  const loading = useSelector((state) => state.starter.loading);

  const [imageLocation, setImageLocation] = useState("");

  const style = {
    height: 60,
    width: 60,
  };

  const imageChange = (index) => {
    setImageLocation(categoriesData[index]?.mainPhoto);
  };

  const handleScrolling = () => {
    if (productsRef?.current) {
      const { scrollTop, scrollHeight, clientHeight } = productsRef.current;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) <= 1 && nextPage) {
        loadPageOnScroll();
      }
    }
  };

  const delayedScrolling = _.debounce(() => {
    handleScrolling();
  }, 300);

  const loadPageOnScroll = () => {
    if (hasNextPage) {
      let payload = { page: nextPage, showProducts: true, productsCount: 5 };

      dispatch(startLoading());
      dispatch(getCategories(payload));
    }
  };

  useEffect(() => {
    dispatch(getCategories({ page: 1, showProducts: true, productsCount: 5 }));
    dispatch(getProducts({ page: 1 }));
  }, []);

  useEffect(() => {
    if (categoriesData?.length) {
      // if (categoriesData[0]?.products[0]?.mainPhoto) {
      //   setImageLocation(categoriesData[0]?.products[0]?.mainPhoto);
      // }
      // else if (categoriesData[1]?.products[0]?.mainPhoto) {
      //   setImageLocation(categoriesData[1]?.products[0]?.mainPhoto)
      // }
      let categoryWithProduct = categoriesData.find((el) => el?.products?.length > 0);
      setImageLocation(categoryWithProduct?.products[0]?.mainPhoto);
    }
  }, [categoriesData]);

  console.log(categoriesData);

  return (
    <div className="Products page-bg">
      <div className="scroll" ref={productsRef} onScroll={delayedScrolling}>
        <PerfectScrollbar>
          <div className="row">
            <div className="col">
              <div className="container">
                <div className="content Products-items-list">
                  <div className="tabs-content">
                    <div className="row">
                      <div className="col col-solution-img">
                        <div className="solution-img">
                          <SwitchTransition mode={"in-out"}>
                            <CSSTransition classNames="fade" timeout={200} key={imageLocation}>
                              <img src={imageLocation} alt="location" />
                            </CSSTransition>
                          </SwitchTransition>
                        </div>
                      </div>
                      <div className="col col-Products-items-list">
                        <div className="scroll">
                          <PerfectScrollbar>
                            <div className="Products-items">
                              {/* {infrastructuresItems} */}

                              {categoriesData?.map((category, index) => {
                                return (
                                  <div
                                    key={category?._id || index}
                                    className="solution-item"
                                    id={category?._id}
                                    onMouseOver={() => {
                                      if (category?.products?.length) setImageLocation(category?.products[0]?.mainPhoto);
                                    }}
                                  >
                                    <NavLink to={category?.products?.length > 1 ? `/Products-list/${category?.name?.en}` : `/product/${category?.products[0]?._id}`}>
                                      <div className="row">
                                        <div className="col col-solution-name">
                                          <span className="col-name">{Utilities.translateLanguage(category?.name)}</span>
                                          <span className="col-info">{category?.products?.map((p) => Utilities.translateLanguage(p?.name)).join(", ")}</span>
                                        </div>
                                        <div className="col col-arrow">
                                          <span className="icon-arrow-right"></span>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </div>
                                );
                              })}

                              {loading && (
                                <div className="solution-item">
                                  {/* {loading && <Lottie animationData={animationData} loop={true} style={style} />} */}
                                  <p className="padding-left">...</p>
                                </div>
                              )}
                            </div>
                          </PerfectScrollbar>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>

      {/* <NavSide /> */}
    </div>
  );
}
