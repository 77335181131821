import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "./styles.scss";

class Socials extends Component {
  render() {
    return (
      <div className="socials">
        <div className="item">
          <NavLink to="/contacts">
            {" "}
            <img src="/icons/locationIcon.svg" alt="location" />
          </NavLink>
        </div>
        <div className="item">
          <a
            href="https://www.linkedin.com/company/hydroprobg/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon-linkedIn"></span>
          </a>
        </div>
        <div className="item">
          <a
            href="https://www.facebook.com/HydroPro-BG-108106688391858/?ref=pages_you_manage"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="icon-facebook"></span>
          </a>
        </div>
      </div>
    );
  }
}

export default connect()(Socials);
