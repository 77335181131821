import React from "react";
import "./styles.scss";
import _ from "lodash";
import { Utilities } from "../../utilities";
import { NewsCard } from "../../components";
import { translate } from "../../translations/localization";
import { getArticles, startLoading } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { history } from "../../config/stores";

export default function AboutUs() {
  const dispatch = useDispatch();
  const newsRef = useRef();

  const newsData = useSelector((state) => state.articles.articles);
  const nextPage = useSelector((state) => state.articles.nextPage);
  const hasNextPage = useSelector((state) => state.articles.hasNextPage);

  const handleScrolling = () => {
    if (newsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = newsRef.current;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && hasNextPage && scrollTop !== 0) {
        loadPageOnScroll();
      }
    }
  };

  const delayedScrolling = _.debounce(() => {
    handleScrolling();
  }, 300);

  const loadPageOnScroll = () => {
    dispatch(startLoading());
    dispatch(getArticles({ page: nextPage }));
  };

  useEffect(() => {
    dispatch(getArticles({ page: 1 }));
  }, [dispatch]);

  return (
    <div className="about-us">
      <div className="container">
        <div className="news-section-wrapper" onScroll={delayedScrolling} ref={newsRef}>
          <h1>{translate("newsTitle")}</h1>

          <div className="news-content-wrapper">
            {newsData?.map((el) => {
              return <NewsCard keyId={el?._id} photoLink={el?.coverPhoto} title={Utilities.translateLanguage(el?.title)} date={el?.createdAt} onClick={() => history.push(`/news/${el?._id}`)} />;
            })}
          </div>
        </div>

        <div className="contact-section-wrapper">
          <h1>{translate("contacts")}</h1>

          <div className="contacts-info-wrapper">
            <div className="row">
              <img src={require("../../assets/icons/phone-blue.svg")} className="icon" alt="icon phone" />
              <p>+359 2/99 280 16 +359 893 644 135</p>
            </div>
            <div className="row">
              <img src={require("../../assets/icons/mail-blue.svg")} className="icon" alt="icon mail" />
              <p>office@hydropro.bg</p>
            </div>
            <div className="row">
              <img src={require("../../assets/icons/location.svg")} className="icon" alt="icon location" />
              <p>{translate("contactsAddress")}</p>
            </div>

            <div className="row-wrapper">
              <div className="row" style={{marginLeft:"2px"}}>
                <img src={require("../../assets/icons/LinkedIn-icon.svg")} className="icon" alt="icon linkedIn" />
                <p style={{ paddingTop: "5px" }} onClick={() => window.open("https://www.linkedin.com/company/hydroprobg/")}>HydroproBG</p>
              </div>
              <div className="row">
                <img src={require("../../assets/icons/facebook.svg")} className="icon" alt="icon facebook" />
                <p onClick={() => window.open("https://www.facebook.com/profile.php?id=100076252747868")}>
                  Hydropro BG
                </p>
              </div>
            </div>
          </div>

          <div className="about-us-info-wrapper">
            <h1>{translate("headerAboutUs")}</h1>

            <img src={require("../../assets/images/about-us.png")} alt="about us image" />
            <p>{translate("hydroproAboutUsInfo")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
